.TB-Container {
  width: 100vw;
  height: 100vh;
  overflow: hidden; /* Ensure no overflow */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.tokenomics-box {
  width: 70vw;
  height: 65vh;
  border: 2px solid #5bb846;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.856);
}
.tokenomics-info h1 {
  font-family: "CustomFont", sans-serif;
  color: aliceblue;
  -webkit-text-stroke: 2.35px #000000;
  font-size: 60px;
  text-align: center;
}
.tokenomics-info p {
  text-align: center;
  max-width: 450px;
  font-weight: 800;
}
.info-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 100px;
}
.info-left {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.info-right {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

h3 {
  font-size: 30px;
  font-family: "CustomFont", sans-serif;
  color: aliceblue;
  -webkit-text-stroke: 2.35px #000000;
}
@media (min-width: 481px) and (max-width: 768px) {
  .tokenomics-info h1 {
    font-size: 50px;
  }

  .info h3 {
    font-size: 21px;
  }
  .info-wrapper {
    gap: 10px;
  }

  .tokenomics-info p {
    font-size: 15px;
  }
}
@media (min-width: 375px) and (max-width: 480px) {
  .tokenomics-info h1 {
    font-size: 30px;
  }

  .info h3 {
    font-size: 20px;
  }
  .info-wrapper {
    gap: 10px;
    padding-left: 25px;
  }

  .tokenomics-info p {
    font-size: 12px;
  }
}
@media (max-width: 375px) {
  .tokenomics-info h1 {
    font-size: 30px;
  }

  .info h3 {
    font-size: 18px;
  }
  .info-wrapper {
    gap: 10px;
    padding-left: 25px;
  }

  .tokenomics-info p {
    font-size: 10px;
  }
}
@media (min-width: 1920px) {
  .tokenomics-info h1 {
    font-size: 100px;
  }

  .info h3 {
    font-size: 40px;
  }
  .info-wrapper {
    gap: 10px;
    padding-left: 25px;
  }

  .tokenomics-info p {
    font-size: 30px;
    max-width: 700px;
    text-align: center;
  }
}
