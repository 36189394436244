@font-face {
  font-family: "CustomFont"; /* Give your font a name */
  src: url("../../public/Font/JungleFeverNF.ttf") format("truetype"); /* Path to the .ttf file */
  font-weight: normal; /* Adjust if needed */
  font-style: normal; /* Adjust if needed */
}

.FB-Container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}
.background-container {
  width: 100vw; /* 100% of viewport width */
  height: 100vh; /* 100% of viewport height */
  overflow: hidden; /* Hide overflow */
  position: absolute; /* Position absolute for the z-index to work properly */
  z-index: -1; /* Place behind other content */
  top: 0;
  left: 0;
}

.bg-tp-left {
  width: 50%;
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 1; /* Ensure image is placed correctly within the container */
}
.bg-tp-right {
  width: 50%;
  position: absolute;
  left: 50vw;
  top: 0px;
  z-index: 1; /* Ensure image is placed correctly within the container */
}
.bg-btm-left {
  width: 50%;
  position: absolute;
  left: 0px;
  top: 50vh;
  z-index: 1; /* Ensure image is placed correctly within the container */
}
.bg-btm-right {
  width: 50%;
  position: absolute;
  left: 50vw;
  top: 50vh;
  z-index: 1; /* Ensure image is placed correctly within the container */
}

.woolie-img {
  width: 30vw;
  height: 60vh;
}
.Header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: -10px;
}
.Header h1 {
  font-size: 90px;
  font-family: "CustomFont", sans-serif;
  color: aliceblue;
  -webkit-text-stroke: 2.35px #000000;
  margin-bottom: -10px;
  margin-top: -7px;
}
.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 20px;
}

.btn {
  display: inline-block;
  width: 5.9vw;
  height: 13vh;
  background-size: cover;
  transition: transform 0.3s ease;
}

.btn:hover {
  transform: scale(1.1);
}

.btn.twitter {
  background-image: url("/public/twitter.png");
  border-radius: 10%;
}

.btn.dexscreener {
  background-image: url("/public/dexscreener.png");
  border-radius: 15%;
}

.btn.telegram {
  background-image: url("/public/telegram.png");
}

.btn.dextools {
  background-image: url("/public/dextools.png");
  border-radius: 15%;
}

.hide-overflow {
  overflow: hidden;
}
.copyable-text {
  color: #2a2a2a;
}

.copyable-text:hover {
  cursor: pointer;
}

.copied-message {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  z-index: 1;
}

@media (min-width: 481px) and (max-width: 768px) {
  .woolie-img {
    width: 300px;
    height: 300px;
  }

  .btn {
    width: 65px;
    height: 65px;
  }

  .corner {
    width: 50vw;
    height: 50vh;
  }
  .Header h1 {
    font-size: 90px;
  }
}

@media (min-width: 375px) and (max-width: 480px) {
  .woolie-img {
    width: 300px;
    height: 300px;
  }

  .btn {
    width: 65px;
    height: 65px;
  }

  .corner {
    width: 50vw;
    height: 50vh;
  }
  .Header h1 {
    font-size: 70px;
    margin-bottom: -5px;
  }
  .copyable-text {
    font-size: 12px;
  }
}
@media (max-width: 375px) {
  .woolie-img {
    width: 300px;
    height: 300px;
  }
  .Header h1 {
    font-size: 70px !important;
  }
  .btn {
    width: 60px;
    height: 60px;
  }

  .corner {
    width: 50vw;
    height: 50vh;
  }
  .Header h1 {
    font-size: 60px !important;
    margin-bottom: -5px;
    margin-top: 12px;
  }
  .copyable-text {
    font-size: 10px;
  }
  .contract-address-bottom {
    font-size: 12px;
  }
}
@media (min-width: 1920px) {
  .btn {
    width: 8vw !important;
  }
  .woolie-img {
    width: 650px;
    height: 650px;
  }
  .copyable-text {
    font-size: 25px;
  }
  .contract-address-bottom {
    font-size: 27px;
  }
  .Header h1 {
    font-size: 150px;
  }
}
