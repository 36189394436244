.SB-Container {
  width: 100vw;
  height: 100vh;
  overflow: hidden; /* Ensure no overflow */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 150px;
  padding-top: 80px;
}

.comic-book {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative; /* To position the buttons correctly */
}

.flip-book {
  width: 300px;
  height: 500px;
}

.page {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page img {
  max-width: 100%;
  max-height: 100%;
}

.navigation-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 20px;
  width: 100%;
  gap: 20px;
}

.nav-button {
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 10px;
  transition: color 0.3s;
}

.nav-button:hover {
  color: #007bff; /* Change to your desired hover color */
}

.nav-button.left {
  margin-right: auto; /* Align left button to the left */
}

.nav-button.right {
  margin-left: auto; /* Align right button to the right */
}

/* In your Secondb.css */
.flip-book .page {
  /* Remove any default hover effects */
  transition: none !important;
}

/* Optional: Override any default styles that might be applied */
.flip-book .page-flip {
  cursor: default !important; /* Remove the cursor change on hover */
}

/* Optionally, you can also add these to ensure no default styles are applied */
.flip-book .page-flip:hover {
  transform: none !important;
  box-shadow: none !important;
}
.SB-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.SB-header h1 {
  font-size: 90px;
  font-family: "CustomFont", sans-serif;
  color: aliceblue;
  -webkit-text-stroke: 2.35px #000000;
  margin-top: 0;
}

.SB-header p {
  max-width: 500px;
  font-size: 20px;
  text-align: center;
  font-weight: 800;

  font-style: normal;
}

@media (min-width: 481px) and (max-width: 768px) {
  .SB-Container {
    flex-direction: column-reverse;
    gap: 0px;
  }
  .SB-header {
    flex-direction: column;
  }

  .SB-header p {
    font-size: 11px;
    padding-bottom: 80px;
    max-width: auto;
  }

  .SB-header h1 {
    display: none;
  }

  .flip-book {
    height: 60vh;
    width: 40vw;
  }
}
@media (min-width: 375px) and (max-width: 480px) {
  .SB-Container {
    flex-direction: column-reverse;
    gap: 0px;
  }
  .SB-header {
    flex-direction: column;
  }

  .SB-header p {
    font-size: 11px;
    padding-bottom: 80px;
    max-width: auto;
  }

  .SB-header h1 {
    display: none;
  }

  .flip-book {
    height: 60vh;
    width: 40vw;
  }
}
@media (max-width: 375px) {
  .SB-Container {
    flex-direction: column-reverse;
    gap: 0px;
  }
  .SB-header {
    flex-direction: column;
  }

  .SB-header p {
    font-size: 11px;
    padding-bottom: 100px;
    max-width: auto;
  }

  .SB-header h1 {
    display: none;
  }

  .flip-book {
    height: 60vh;
    width: 40vw;
  }
}
@media (min-width: 1920px) {
  .SB-header p {
    font-size: 30px;
    padding-bottom: 0px;
    max-width: 700px;
  }
}
